import constants from "../../utils/constants";

const initialState = { isAuthenticated: true, data: {} };

const auth = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN: {
      return {
        isAuthenticated: true,
        data: action.payload,
      };
    }
    case constants.USER_DETAILS: {
      return {
        isAuthenticated: true,
        data: action.payload,
      };
    }
    case constants.LOGOUT: {
      return {
        isAuthenticated: false,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
