import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  createProductCategory,
  updateProductCategory,
  uploadSingleDocument,
  getCollections,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import SelectField from "../../components/Select/Select";
import { set } from "lodash";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    docId: data?.document?.id || "",
    name: data?.name || "",
    image: data?.document?.url || "",
    collections:
      data?.collections?.map((el) => ({
        label: el.title,
        value: el.id,
      })) || [],
    file: "",
  });
  const [collections, setCollections] = useState([]);

  const [errors, setErrors] = useState({
    docId: "",
    name: "",
    collections: "",
  });

  const openHandler = useCallback(() => {
    setErrors({
      docId: "",
      name: "",
      collections: "",  
    })
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();console.log(formData.collections);
      
      let cleanData = {
        name: formData?.name?.trim(),
        docId: docId || formData?.docId?.trim(),
        collectionIds: formData?.collections?.map((el) => el.value) || [],
      };

      let tempErrors = {};

      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }

      if (!cleanData.name) {
        tempErrors["name"] = "Name is required";
      }

      if (cleanData.collectionIds?.length < 1) {
        tempErrors["collections"] = "Collection is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
        const apiResponse =   await updateProductCategory(data?.id, cleanData);
        toast.success(apiResponse?.data?.message);
      } else {
       const apiResponse =   await createProductCategory(cleanData);
       toast.success(apiResponse?.data?.message);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?.id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
        collections:
          data?.collections?.map((el) => ({
            label: el.title,
            value: el.id,
          })) || [],
      });
      setErrors({
        docId: "",
        name: "",
        image: "",
        file: "",
        collections: [],
      });
      fetch();
    } catch (error) {
      console.log(error);
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        docId: data?.document?.id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
        collections:
          data?.collections?.map((el) => ({
            label: el.title,
            value: el.id,
          })) || [],
      });
    } else {
      setFormData({
        docId: "",
        name: "",
        image: "",
        file: "",
        collections: [],
      });
    }
  }, [data?.id]);

  const fileHandler = useCallback(
    (event) => {
      console.log(event?.target?.files[0]);
      if (event?.target?.files[0]) {
        setFormData({
          ...formData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      }
    },
    [formData]
  );

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fetchCollections = useCallback(async () => {
    try {
      const apiResponse = await getCollections();
      if (apiResponse?.data?.data?.collections?.length > 0) {
        setCollections([
          ...apiResponse.data.data.collections.map((el) => ({
            value: el.id,
            label: el.title,
          })),
        ]);
      } else {
        setCollections([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      setFormData({
        docId: data?.document?.id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
        collections:
          data?.collections?.map((el) => ({
            label: el.title,
            value: el.id,
          })) || [],
      });
      fetchCollections();
    }
  }, [open]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>
              {data?.id ? "Edit Product Category" : "Add Product Category"}
            </h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
            <div
                className="input-wrapper-image"
                style={{
                  border: !formData.image ? "2px solid #ccc" : "none",
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  position: "relative", // Ensure proper alignment of child elements
                }}
              >
                {formData.image ? (
                  <div className="image">
                    <img
                      src={formData.image}
                      alt="Uploaded Preview"
                      onClick={() => document.getElementById(type).click()} // Trigger file input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Ensure the image fits within the defined size
                    />
                  </div>
                ) : (
                  <label
                    htmlFor={type}
                    className="upload-button"
                    style={{
                      cursor: "pointer",
                      color: "#555",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column", // Optional for multiple lines of text
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    Upload Image{" "}
                    {formData.type?.value === "Smart mirror(Home)"
                      ? "(Aspect ratio - 16:9)"
                      : "(Aspect ratio - 3:2)"}
                  </label>
                )}
                <input
                  onChange={fileHandler}
                  accept="image/*"
                  id={type}
                  type="file"
                  style={{ display: "none" }} // Hide the input field
                />
                  {errors?.docId && <p style={{position: "absolute", bottom: "-20px"}} className="error-text">{errors?.docId}</p>}

              </div>

              <div className="input-wrapper">
                <label>Name</label>
                <input
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
                {errors.name && <p>{errors.name}</p>}
              </div>
            </div>
            <div className="input-wrapper">
              <label>Collections</label>
              <SelectField
                name="collections"
                onChange={selectDropdownChangeHandler}
                value={formData.collections}
                isMulti={true}
                options={collections}
              />
              {errors.collections && <p>{errors.collections}</p>}
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
