import React from "react";
import { connect } from "react-redux";

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.loader,
});

export default connect(mapStateToProps, null)(Loader);
