import React, { useState } from "react";
import Select from "react-select";
import styled from "styled-components";

export const SelectField = ({
  onChange,
  value,
  name,
  placeholder,
  options,
  isMulti,
}) => {
  const handleChange = (selectedOption) => {
    onChange(name, selectedOption);
  };

  return (
    <StyledSelect
      name={name}
      value={value}
      onChange={handleChange}
      options={options}
      clearable={true}
      className="react-select"
      placeholder={placeholder}
      classNamePrefix="react-select"
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
    />
  );
};

export default SelectField;

// region STYLES

const StyledSelect = styled(Select)`
  width: 100%;
  min-height: 40px;
  font-size: 12px;

  .react-select__control {
    min-height: 32px;
    height: auto;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid rgb(51, 51, 58);
    background-color: transparent;

    &.react-select__control--is-focused,
    &:hover {
      border-color: #eacc76 !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__dropdown-indicator {
  transition: transform 0.2s ease-in-out;
}

.react-select__control--menu-is-open .react-select__dropdown-indicator {
  transform: rotate(180deg);
}


  .react-select__input {
    height: 30px;
    color: rgb(221, 221, 221) !important;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: #605f7b;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid #eacc76;

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid #eacc76;
      color: #605f7b;
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: #605f7b;
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: #232329;
    color: rgb(221, 221, 221) !important;
  }

  .react-select__multi-value__label,
  .react-select__single-value {
    color: rgb(221, 221, 221) !important;
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: rgb(35, 35, 41);
    border: 1px solid rgb(51, 51, 58);
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__option {
    color: rgb(221, 221, 221);
  }
  .css-d7l1ni-option,
  .css-tr4s17-option {
    background-color: #eacc76;
    color: #141414;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    bacground-color: #eacc76;
    color: #141414;
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;
