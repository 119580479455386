import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getProductPromocodes,
  activateDeactivateProductPromocode,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";
import Pagination from "../../components/Pagination/Pagination";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const ProductPromocodes = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState({});
  const navigate=useNavigate()
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getProductPromocodes(search);
      if (apiResponse?.data?.data?.productPromocodes?.length > 0) {
        setData({...apiResponse?.data?.data});
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname, search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname, search]);
  const activateDeactivateProductPromocodeHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        await activateDeactivateProductPromocode(id, { requestType });
        fetch();
        toast.success(
          requestType === 1
            ? "Promocode activated successfully"
            : "Promocode deactivated successfully"
        );
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []
  );

  const searchChangeHandler = useCallback((event) => {
    navigate(`/product-promocodes?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/product-promocodes?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

console.log(data);

  return (
    <div className="section">
      <h1>Product Promocodes</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
          <div className="search">
          <div className="input-wrapper">
      <input
        value={searchValue}
        onChange={searchChangeHandler}
        placeholder="Search..."
      />
      {searchValue && (
        <RxCrossCircled onClick={resetSearchHandler} size={18} />
      )}
    </div>
            </div>
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Created at</th>
                <th>Description</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.productPromocodes?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td>{item.description}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive ? (
                          <>
                            <AddEdit data={item} fetch={fetch} />
                            <button
                              className="danger"
                              onClick={() =>
                                activateDeactivateProductPromocodeHandler(
                                  item.id,
                                  0
                                )
                              }
                            >
                              Deactivate
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              activateDeactivateProductPromocodeHandler(
                                item.id,
                                1
                              )
                            }
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ProductPromocodes);
