import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { FaRegUser } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { login } from "../../apis";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Login = ({ loginRequest, stopLoadingRequest, startLoadingRequest }) => {
  const navigate = useNavigate();
  
  // Refs for email field focus
  const emailRef = useRef(null);

  const initialState = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      navigate("/dashboard?requestType=Today");
    } else {
      stopLoadingRequest();
    }

    // Set focus on email field on component mount
    emailRef.current?.focus();
  }, []);

  // Formik Setup with validation
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|gov|edu|in|io|co|uk|us|info|biz|me)$/,
          "Invalid email format"
        )
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),    onSubmit: async (values) => {
      startLoadingRequest();
      try {
        let apiResponse = await login(values);
        if (apiResponse?.data?.data?.accessToken) {
          localStorage.setItem(
            "authorization",
            apiResponse.data.data.accessToken
          );
          loginRequest(apiResponse?.data?.data?.adminDetails);
          stopLoadingRequest();
          navigate("/dashboard?requestType=Today");
          toast(apiResponse.data.message)
        }
        formik.resetForm();
      } catch (error) {
        stopLoadingRequest();
        toast.error(error.response?.data.message);
      }
    },
  });

  // Handle Enter key press to submit the form
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <div className="login-container">
      <form className="login-wrapper" onSubmit={formik.handleSubmit}>
        <div className="header">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="content">
          <div className="input-wrapper">
            <label>Email</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaRegUser size={14} />
              </div>
              <input
                name="email"
                ref={emailRef}  // Ref added here
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="email"
                onKeyDown={handleKeyDown}  // Handle Enter key
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="error" style={{color:"#eacc76"}}>{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="input-wrapper">
            <label>Password</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaKey size={13} />
              </div>
              <input
                type={formik.values.showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                placeholder="password"
                onKeyDown={handleKeyDown}  // Handle Enter key
              />
              <div
                onClick={() => formik.setFieldValue('showPassword', !formik.values.showPassword)}
                className={formik.values.showPassword ? "icon active" : "icon"}
              >
                <FaEye size={16} />
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="error" style={{color:"#eacc76"}}>{formik.errors.password}</div>
            ) : null}
          </div>
        </div>
        <div className="footer">
          <button type="submit" className="primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginRequest: actions.auth.login,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
