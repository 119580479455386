import constants from "../utils/constants";

const login = (payload) => ({
  type: constants.LOGIN,
  payload,
});

const userDetails = (payload) => ({
  type: constants.USER_DETAILS,
  payload,
});

const logout = () => ({
  type: constants.LOGOUT,
});

export default {
  login,
  userDetails,
  logout,
};
