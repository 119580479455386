import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { changePassword } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const ChangePassword = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
  });

  const [errors, setErrors] = useState({
    password: "",
  });

  const openHandler = useCallback(() => {
    console.log("hit");
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = { password: formData?.password?.trim() };
      let tempErrors = {};
      if (!data?.id && cleanData.password?.length < 8) {
        tempErrors["password"] = "Password must have 8 characters";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }

    const response = await changePassword(data?.id, cleanData);
    toast("Password updated Successfully")
      setOpen(false);
      setFormData({
        password: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button className="info" onClick={openHandler}>
          Change Password
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>Change Password</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>Password</label>
                <input
                  name="password"
                  value={formData.password}
                  onChange={changeHandler}
                />
                {errors.password && <p>{errors.password}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChangePassword);
