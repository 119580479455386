import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getUsers, activateDeactivateUser } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";

const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  // const [searchValue, setSearchValue] = useState("");
  const { pathname, search } = useLocation();
const [searchParams] = useSearchParams();
const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getUsers(search);
      if (apiResponse?.data?.data?.users?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname, search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  //   if (search) {
  //     setSearchValue(
  //       search
  //         ?.split("?")[1]
  //         ?.split("&")
  //         ?.filter((el) => el.includes("search"))[0]
  //         ?.split("=")[1] || ""
  //     );
  //   }
  // }, [pathname, search]);

  const activateDeactivateUserHandler = useCallback(async (id, requestType) => {
    try {
      startLoadingRequest();
      await activateDeactivateUser(id, { requestType });
      fetch();
      toast.success(
        requestType === 1
          ? "User activated successfully"
          : "User deactivated successfully"
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, []);

  const searchChangeHandler = useCallback((event) => {
    navigate(`/users?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/users?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);
  return (
    <div className="section">
      <h1>Users</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
          <div className="search">
          <div className="input-wrapper">
      <input
        value={searchValue}
        onChange={searchChangeHandler}
        placeholder="Search..."
      />
      {searchValue && (
        <RxCrossCircled onClick={resetSearchHandler} size={18} />
      )}
    </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Onboarding date</th>
                {/* <th>Profile picture</th> */}
                <th>Code</th>
                <th>Name</th>
                {/* <th>Gender</th> */}
                <th>Phone number</th>
                <th>Email</th>
                <th>Active/Inactive</th>
                {/* <th>Last updated at</th> */}
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.users?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.createdAt}</td>
                    {/* <td>{item?.image && <img src={item.image} />}</td> */}
                    <td>{item.code}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item.firstName} {item.lastName}
                    </td>
                    {/* <td>{item.gender}</td> */}
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    {/* <td>{item.updatedAt}</td> */}
                    <td>
                      <div className="table-actions">
                        {item?.isActive ? (
                          <>
                            <button
                              className="danger"
                              onClick={() =>
                                activateDeactivateUserHandler(item.id, 0)
                              }
                            >
                              Deactivate
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              activateDeactivateUserHandler(item.id, 1)
                            }
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.users?.length > 0 && <Pagination pageInfo={data.pageInfo} />}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Users);
