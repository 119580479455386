import constants from "../../utils/constants";

const initialState = true;

const loader = (state = initialState, action) => {
  switch (action.type) {
    case constants.START_LOADING: {
      return true;
    }
    case constants.STOP_LOADING: {
      return false;
    }

    default: {
      return state;
    }
  }
};

export default loader;
