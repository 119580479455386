import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getBlogCategories, activateDeactivateBlogCategory } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";
import Pagination from "../../components/Pagination/Pagination";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const BlogCategories = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState({});
  const navigate=useNavigate()
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getBlogCategories(search);
      if (apiResponse?.data?.data?.blogCategories?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname,search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);
  const activateDeactivateBlogCategoryHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
       const response = await activateDeactivateBlogCategory(id, {
          requestType: requestType ? "0" : "1",
        });
        toast.success(response.data.message);
        fetch();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []
  );

  const searchChangeHandler = useCallback((event) => {
    navigate(`/blog-categories?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/blog-categories?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  return (
    <div className="section">
      <h1>Blog Categories</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
          <div className="search">
          <div className="input-wrapper">
      <input
        value={searchValue}
        onChange={searchChangeHandler}
        placeholder="Search..."
      />
      {searchValue && (
        <RxCrossCircled onClick={resetSearchHandler} size={18} />
      )}
    </div>
            </div>
            <AddEdit type={"blog-category-add"} fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.blogCategories?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      {item?.doc?.url && <img src={item.doc.url} />}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item.isActive && (
                          <>
                            <AddEdit
                              type={`blog-category-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateBlogCategoryHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BlogCategories);
