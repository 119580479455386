import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import { getPermissions, createAdmin, updateAdmin } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import isEqual from 'lodash.isequal';
const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    permissions:
      data?.permissions?.map((el) => ({
        value: el.id,
        label: el.name,
      })) || [],
    gender: { value: data?.gender || "", label: data?.gender || "" },
    password: "",
  });

  const [initialFormData, setInitialFormData] = useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    permissions:
      data?.permissions?.map((el) => ({
        value: el.id,
        label: el.name,
      })) || [],
    gender: { value: data?.gender || "", label: data?.gender || "" },
    password: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    permissions: "",
    gender: "",
    password: "",
  });

  const [permissions, setPermissions] = useState([]);

  const openHandler = useCallback(() => {
    setErrors({});  
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  
  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      if (name === "permissions") {
        if (value.some((item) => item.value === "all123")) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            permissions: [{ value: "all123", label: "All" }],
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            permissions: value,
          }));
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    },
    []
  );

  const fetchPermissions = useCallback(async () => {
    try {
      const apiResponse = await getPermissions();
      if (apiResponse?.data?.data?.permissions?.length > 0) {
        setPermissions([
          { value: "all123", label: "All" },
          ...apiResponse.data.data.permissions.map((el) => ({
            value: el.id,
            label: el.name,
          })),
        ]);
      } else {
        setPermissions([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        email: data?.email || "",
        permissions:
          data?.permissions?.map((el) => ({
            value: el.id,
            label: el.name,
          })) || [],
        gender: { value: data?.gender || "", label: data?.gender || "" },
        password: "",
      });
      fetchPermissions();
    }
  }, [open]);

  useEffect(() => {
    setInitialFormData({
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      permissions:
        data?.permissions?.map((el) => ({
          value: el.id,
          label: el.name,
        })) || [],
      gender: { value: data?.gender || "", label: data?.gender || "" },
      password: "",
    });
  }, [data]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        firstName: formData?.firstName?.trim(),
        lastName: formData?.lastName?.trim(),
        email: formData?.email?.trim(),
        permissions:
          formData?.permissions
            ?.filter((el) => el.value?.trim() !== "")
            ?.map((el) => el.value) || [],
        gender: formData?.gender?.value,
        ...(!data?.id && { password: formData?.password?.trim() }),
      };
      let tempErrors = {};
      if (!cleanData.firstName) {
        tempErrors["firstName"] = "First name is required";
      }
      if (!cleanData.lastName) {
        tempErrors["lastName"] = "Last name is required";
      }
      // Password validation
    if (!data?.id) {
      if (!cleanData.password) {
        tempErrors["password"] = "Password is required";
      } else if (cleanData.password?.length < 8) {
        tempErrors["password"] = "Password must have 8 characters";
      }
    }

    // Email validation
    if (!cleanData.email) {
      tempErrors["email"] = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanData.email)) {
      tempErrors["email"] = "Invalid email format";
    }
      if (!cleanData.gender) {
        tempErrors["gender"] = "Gender is required";
      }
      if (cleanData.permissions?.length < 1) {
        tempErrors["permissions"] = "Permissions is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
        const response = await updateAdmin(data?.id, cleanData);
        toast(response.data.message);
      } else {
        const response = await createAdmin(cleanData);
        toast(response.data.message);
      }
      setOpen(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        permissions: [{ value: "", label: "" }],
        gender: { value: "", label: "" },
        password: "",
      });
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        permissions: "",
        gender: "",
        password: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const isDisabled = data?.id ? isEqual(formData, initialFormData) : false;
  
  

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit Admin" : "Add Admin"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>First Name</label>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={changeHandler}
                />
                {errors.firstName && <p>{errors.firstName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Last Name</label>
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={changeHandler}
                />
                {errors.lastName && <p>{errors.lastName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Email</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
                {errors.email && <p>{errors.email}</p>}
              </div>
              {!data?.id && (
                 <div className="input-wrapper">
      <label>Password</label>
      <div style={{ position: 'relative' }}>
        <input
          type={isPasswordVisible ? 'text' : 'password'} // Toggle input type
          name="password"
          value={formData.password}
          onChange={changeHandler}
          style={{ paddingRight: '30px' }} // Add space for the eye icon
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            fontSize: '18px', // Adjust the size of the icon
          }}
        >
          {isPasswordVisible ? '👁️' : '🙈'} {/* Eye icon */}
        </span>
      </div>
      {errors.password && <p>{errors.password}</p>}
    </div>
              )}
              <div className="input-wrapper">
                <label>Gender</label>
                <SelectField
                  name="gender"
                  onChange={selectDropdownChangeHandler}
                  value={formData.gender}
                  isMulti={false}
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Not Specified",
                      label: "Not Specified",
                    },
                  ]}
                />
                {errors.gender && <p>{errors.gender}</p>}
              </div>
              <div className="input-wrapper">
                <label>Permissions</label>
                <SelectField
                  name="permissions"
                  onChange={selectDropdownChangeHandler}
                  value={formData.permissions}
                  isMulti={true}
                  options={permissions}
                />
                {errors.permissions && <p>{errors.permissions}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
          <button
  type="button"
  onClick={submitHandler}
  style={isDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
  disabled={isDisabled}
  className="primary"
>
  Submit
</button>

          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
